import React from 'react';
import Cookies from 'js-cookie';
import { Navigate, useParams } from 'react-router-dom';

export const RequireAuth = ({ children }) => {

    const { lang } = useParams();
    let isLogged = Cookies.get('auth-token');

    if (!isLogged) {
        return <Navigate to={`${lang ? `/${lang}` : ''}/login`} />
    }
    return children
};